/*********** MY PROFILE ***********/

.my-profile {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  position: relative;
  height: 100%;
}

#account-manager-dashboard {
  .my-profile {
    height: auto;
  }
}

.my-profile-header {
  height: 272px;
  background: #D4DAF9;
  background-image: url(../../images/profile-img.png);
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right top;
  text-align: left;
  padding: 20px;
  border-radius: 4px;
}

.my-profile-header>p,
.my-profile-header>h5 {
  color: $primary;
}

.my-profile-avatar {
  border: 10px solid white;
  position: absolute;
  top: 174px;
  left: 20px;

  i {
    font-size: 52px;
  }
}

.my-profile-content {
  padding: 74px 20px 20px 20px;
  border-radius: 4px;
}

/*********** INACTIVE ORDERS ***********/

.inactive-orders-card {
  border-radius: 4px;
}

/*********** DOUGHNUT CHART ***********/

.btn-active {
  color: black !important;
  border: none;
}

.btn-active:focus {
  color: #ffff !important;
}

.img-store {
  width: 6em !important;
  height: auto;
  margin-right: 0.5em;
  margin-top: 0.5em;
}

.chart-placeholder {
  text-align: center;

  .placeholder-description {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 23px;
    color: $gray-650;
  }
}

/*********** TOP ***********/

.top-card {
  min-height: 440px;
  height: calc(100% - 24px);
}

#account-manager-dashboard {
  .top-card {
    height: auto;
    min-height: auto;
    padding: 10px 0;
  }
}

.top-store-name {
  color: $gray-700;
  font-size: 13px;
}

.progress-col {
  width: 45%;
}

/*********** SUBSCRIPTIONS ***********/

.subscriptions-card {
  min-height: 440px;
  height: calc(100% - 24px);

  .horizontal-scroll-table {
    overflow: auto;
  }
}

/*********** CUSTOMER PERFORMANCE ***********/
.remote-customer-perf-card {

  .orders-status-slider {

    .carousel {

      .carousel-controls {

        .carousel-control-prev,
        .carousel-control-next {
          position: static;
          width: 30px;
          height: 30px;
          display: block;
        }

        .carousel-control-prev-icon {
          background-image: url('../../images/carousel-left-arrow.svg');
          background-size: auto;
        }

        .carousel-control-next-icon {
          background-image: url('../../images/carousel-right-arrow.svg');
          background-size: auto;
        }
      }

      .carousel-indicators {
        position: static;
        text-align: center;
        margin: 0;

        button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #74788D40;
          opacity: 1;
          border: 0;
          margin: 0 10px;

          &.active {
            background: #74788D;
          }
        }
      }

    }
  }

  #account-manager-dashboard & {
    height: 540px;
    padding: 20px 0px;
  }

  .overlap-chart {
    z-index: 999;
  }

  .card-header {
    justify-content: flex-end;
  }
}

/*********** MESSAGES ***********/

.messages-card {
  .message-content {

    // apply ellipsis for larger screen sizes
    // because for smaller screens, react-super-responsive-table displays each table entry in a card
    @media screen and (min-width: 767px) {
      width: 35%;
      max-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.table-badge {
  border-radius: 30px;
  font-size: 11px;
  font-weight: 400;
  padding: 6px 16px;
}

/*********** SCHEDULER MESSAGES ***********/


.div-conversation {
  display: flex;
  border: solid 1.5px black;
  margin-bottom: 1.5em;
  justify-content: space-around;
  padding: 0.5em 0;
  align-items: center;
}

/*********** STATS AND ACTIONS ***********/

.stats-icon {
  align-items: center;
  display: flex;
  font-weight: $fw-medium;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.primary-icon-color {
  background-color: rgba($primary, 0.4);
  color: $primary;
}

.primary-icon-color-light {
  background-color: rgba($primary, 0.15);
  color: $primary;
}

.purple-icon-color {
  background-color: rgba($purple, 0.15);
  color: $purple;
}

.green-icon-color {
  background-color: rgba($green, 0.15);
  color: $green;
}

.icon-circle {
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin-right: 20px;
  min-width: 48px;
}

.icon-circle.avatar-xs {
  min-width: 32px;
}

.action-card {
  height: 130px;
  cursor: pointer;
}

.action-link,
.action-link:hover {
  color: $gray-700;
}

.trend-percentage {
  width: auto;
  white-space: nowrap;
  align-self: flex-start;
}

.trend-interval {
  color: $text-muted;
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;
}

/*********** COMMON ***********/

// used to add an horizontal scrollbar when content is too wide
.horizontal-scroll-table {
  @media screen and (max-width: 768px) {
    overflow-x: auto;
  }
}

.horizontal-scroll-table>table {
  @media screen and (max-width: 768px) {
    width: 200%;
  }
}

.footer-link {
  position: absolute;
  right: 20px;
  bottom: 10px
}

.messages-table {
  max-height: 380px;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%
}

.sender-message-name {
  color: #556ee6;
}

.message-parent {
  white-space: nowrap;
  display: flex;
}

.message-content-mobile {
  max-width: 50px;
  display: inline-block
}

.message-card-mobile {
  max-width: 100px;
  display: inline-block
}