/****************** GLBA Assessment View Card ******************/
.glba-assessment-card {
   color: #495057;

  .column-title {
    font-size: 13px;
    font-weight: 600;
    line-height: 19px;
    color: #222;
  }

  .column-question {
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .column-answer,
  .column-title {
    border-bottom: 1px solid #EFF2F7;
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .right-border {
    border-right: 1px solid #EFF2F7;
  }
}
