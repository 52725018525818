// Order Actions Section
.order-actions {

  .display-status {
    padding: 0 1rem;

    img {
      width: 24px;
    }
  }

  .divider {
    border-left: 1px solid #EFEFEF;
    height: 3rem;
  }

  .fraud-message {
    background-color: #FCF0DB;
    color: #916C2E;
    margin-bottom: 1rem;
    font-size: 13px;
  }

  .header-title {
    font-size: 15px;
  }

  .header-stats {
    font-size: 11px;
    max-width: 100px;
  }

  .header-badge {
    max-width: fit-content;
  }
}

/* Styles for Vehicle Information card - nav bar */
.vehicle-information {

  .nav {
   overflow-x: auto;
   overflow-y:hidden;
   flex-wrap: nowrap;

    .nav-link,
    .nav-item {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center;
      white-space: nowrap;
    }
  }
}

/* Styles for Identity Validation Skipped section */
.identity-validation-skipped {
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px  solid $gray-600;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: $gray-650;

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #2A3042;
    padding-left: 10px;
  }
}

/**** REVIEW BUTTONS ******/

.review-action-buttons {

  .btn-danger {
    background-color: #e45757;
    border-color: #e45757;
  }

 .btn-warning {
    background-color: #e1aa4a;
    border-color: #e1aa4a;
  }
}

@media (min-width: 1650px) {

  .fraud-message {
    font-size: 17px;
  }

  .header-badge {
    padding: 0.4rem 0.7rem;
    font-size: inherit;
  }

  .header-stats {
    font-size: 14px;
  }

  .header-title {
    font-size: 18px;
  }
}

@media (max-width: 767px){
  .order-actions {
    .col-6,
    .col-12 {
      margin-bottom: 1rem;
    }

    .display-status {
      display: block!important;

      > .d-flex:not(:last-of-type) {
        margin-bottom: 1rem;
        border-bottom: 1px solid #EFEFEF;
        padding-bottom: 1.5rem;
      }
    }

    .divider {
      display: none;
    }

    .header-stats {
      max-width: 100%;
    }
  }
}
