body[data-sidebar="dark"] .navbar-brand-box {
  background-color: #1D71B8;
  height: 70px;
}

.logo-size-33 {
  width: 33px;
  height: 33px;
}

.main-content {
  min-height: 100vh;
}

.footer {
  left: 0;
  z-index: 1001;
}

.vertical-menu {
  padding-bottom: 70px;
}

.spinner-chase.xxs {
  width: 9px;
  height: 9px;
}

.spinner-chase.xs {
  width: 24px;
  height: 24px;
}

.spinner-chase.sm {
  width: 32px;
  height: 32px;
}

.spinner-chase.dtable {
  // datatable row height - spinner height
  margin-bottom: calc(44.5px - 32px);
}

.is-invalid .select2-selection__control {
  border-color: #f34943 !important;
}

.card-header:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}

#preloader.inner {
  background-color: transparent;
  left: 250px; // width of vertical-menu
}

.card.expand-v {
  height: calc(100% - 24px);
}

.card-section {
  padding: 0 $card-spacer-x;
}

.card,
.card-section {

  &.blue {
    background-color: #EEF4FD;
  }

  // Supporting docs label
  .supporting-docs-label {
    background: #EEF0FD;
    padding: 8px 16px;
    border-radius: 8px;
    margin: 0;
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
    color: $blue;
  }
}

.btn-faded {
  opacity: 0.65;
}

.search-label {
  width: 100%;
}

.badge-lg {
  padding: 8px;
  font-size: inherit;
}

.badge-md {
  padding: 4px 8px;
  font-size: inherit;
}

.section-rows {
  margin-bottom: 0;

  td {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }

  .notary-profile & {
    border-top: 1px solid $gray-100;
  }
}

.section-row-label {
  width: 30%;

  .view-accounting & {
    width: 80%;
  }
}

.section-row-value {
  font-size: 16px;
  font-weight: 500;
}

.select2-selection__menu {
  z-index: 1002 !important;
}

.page-title-box h4 {
  // since we are combining page title with breadcrumbs
  // do not make title upercase by default
  // we want to have the flexibility to choose on a case by case basis
  text-transform: none;
}

.top-heading {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-bottom: 0;
  font-size: 16px;
}

.auth-user-name {
  vertical-align: middle;
  line-height: 1.2;
}

.auth-user-role {
  display: block;
  font-size: 70%;
}

.bg-success-dark {
  background-color: $green-700;
}

.btn-success-dark,
.btn-success-dark:hover {
  color: #fff;
  background-color: $green-700;
  border-color: $green-700;
}

.bg-primary-dark {
  background-color: $blue-600;
}

.btn-primary-dark,
.btn-primary-dark:hover {
  color: #fff;
  background-color: $blue-600;
  border-color: $blue-600;
}

.bg-orange {
  background-color: $orange-400;
}

.btn-orange,
.btn-orange:hover {
  color: #fff;
  background-color: $orange-400;
  border-color: $orange-400;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272B;
  border-color: #1D2124;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #23272B;
  border-color: #1D2124;
  -webkit-box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
  box-shadow: 0 0 0 0.15rem rgba(82, 88, 93, 0.5);
}

.text-orange {
  color: $orange-400;
}

.text-inherit {
  color: inherit;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default !important;
}

.vertical-divider {
  border-left: 1px solid $gray-400;
}

.disable-input-arrows {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  appearance: textfield;
  -moz-appearance: textfield;
}

.square-icon-btn {
  width: 36px;
  min-height: 36px;
  padding: 0;

  i {
    font-size: 20px;
  }
}

thead.vertical-align-middle {
  vertical-align: middle;
}

.form-check-area {
  margin-left: 25px
}

.inline-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.collect-form-title {
  color: #74788D;
  font-size: 14px;
  font-weight: 500
}

.text-right {
  text-align: right;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.active-notary-row {
  background-color: #556ee6;
  color: white !important;
  ;
}

.text-white-color {
  color: #fff !important;
}

.text-processing {
  color: $tangerine;
}