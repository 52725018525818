.route-one {

  .card {
    padding: 24px;
  }

  /******* Banner ******/
  .route-one-banner {
    background: url(../../images/route-one-banner.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    background-color: #EEF0FD;
    padding: 20px;
    min-height: 280px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .description-wrapper {
      .title {
        font-family: Poppins;
        font-size: 40px;
        font-weight: 600;
        line-height: 44px;
        color: #2E2E2E;
      }

      .description {
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: #74788D;
        max-width: 450px;
      }
    }
  }

  /******* Accordeon ******/
  .route-one-accordion-container {
    width: 80%;
    margin: 0 auto;

    .accordion-item {
      background: #F8F9FA;
      border: 0;
      border-radius: 4px;

      .accordion-button {
        box-shadow: none;
        background: #F8F9FA;
        color: #495057;
      }

      .accordion-header {
        .accordion-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #495057;
        }

        .accordion-description {
          font-size: 14px;
          line-height: 18px;
          padding-right: 5px;
        }
      }

      .accordion-body {
        .step-title {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: #000000;
        }

        .step-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #74788D;
        }
      }

      iframe {
        margin: 0 auto 20px;
        display: flex;
      }
    }
  }
}
