.user-activity-status {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  line-height: 1;
}

.user-activity-status-circle {
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  border-color: #ffffff;
  border-radius: 50%;
}

.user-activity-status-text {
  display: inline-block;
  vertical-align: middle;
}

.user-activity-status-sm {

  .user-activity-status-circle {
    width: 12px;
    height: 12px;
    border-width: 2px;
  }
}

.user-activity-status-md {

  .user-activity-status-circle {
    width: 13px;
    height: 13px;
    border-width: 2px;
  }
}

.user-activity-status-lg {

  .user-activity-status-circle {
    width: 16px;
    height: 16px;
    border-width: 3px;
  }
}

.user-activity-status-xl {

  .user-activity-status-circle {
    width: 20px;
    height: 20px;
    border-width: 4px;
  }
}

.user-activity-status-xxl {

  .user-activity-status-circle {
    width: 26px;
    height: 26px;
    border-width: 4px;
  }
}

.user-avatar-with-activity-status {
  display: inline-block;
  position: relative;
  vertical-align: middle;

  &.in-table {
    margin-top: -10px;
    margin-bottom: -10px;
  }

  .user-activity-status {
    position: absolute;
  }

  .user-activity-status-sm {
    bottom: 0;
    right: 0;
  }

  .user-activity-status-md {
    bottom: 2px;
    right: 2px;
  }

  .user-activity-status-lg {
    bottom: 4px;
    right: 4px;
  }

  .user-activity-status-xl {
    bottom: 17px;
    right: 17px;
  }

  .user-activity-status-xxl {
    bottom: 28px;
    right: 28px;
  }
}