.react-bootstrap-table {

  thead th {
    cursor: pointer;
  }

  tbody {
    .button-status-p {
      padding: 0.47rem 0.75rem;
    }

    .notary-bid-highlight-row {
      background: rgba(85, 110, 230, 0.5);
    }
  }

  .dropup>.caret {
    margin: 10px 0;
    color: #cccccc;
  }

  .dropup .caret,
  .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
  }

  th>.react-bootstrap-table-sort-order>.caret {
    margin: 10px 6.5px;
  }

  .hide-expand-all {
    .expand-cell-header {
      display: none;
      pointer-events: none;
    }
  }

  .expand-cell {

    .accordion-button:not(.collapsed),
    .accordion-button:not(.collapsed)::after {
      background-color: transparent;
      box-shadow: none;
    }
  }

  .assign-notary-disabled {
    padding: 0.47rem 0.75rem;
    pointer-events: none;
    cursor: pointer;
  }
}

.react-bootstrap-table,
.inner-custom-pagination {

  .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

.dtable-icon {
  font-size: 20px;
  display: inline-block;
  margin-top: -8px;
  margin-bottom: -8px;
}

.search-box .form-control {
  border-radius: 0.25rem;
}

.close-dtable-filters-btn {
  position: absolute;
  top: 1px;
  right: 3px;
  font-size: 18px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.react-bs-table-no-data {
  text-align: center;
  border-bottom: none;
}

.table .badge-lg {
  margin-top: -5px;
  margin-bottom: -5px;
}

.offcanvas-backdrop.show {
  opacity: 0.1;
}

@include media-breakpoint-down(sm) {

  .search-box {
    width: 100%;
  }
}

.value-sep {
  padding: 0 3px;
}

.table-empty {
  border-bottom-width: 0 !important;
  text-align: center;
}

.paginated-table-card {
  margin-bottom: 120px; // to account for the size-per-page dropdown
}

.must-upload-icon {
  height: 28px;
}

.row-border-spacing {
  border-bottom: 21px solid white;
}

.notary-selected-highlight-row {
  background: rgba(248, 249, 250, 1);
}

.accepted-awarded-bid {
  background: rgb(93, 219, 173) !important;
}

.notary-declined-highlight-row {
  background: rgba(246, 188, 188, 1);
}

.next-notif-timer {
  position: absolute;
  top: -20px;
  display: flex;
  color: rgba(85, 110, 230, 1);
}

.until-next-notification {
  animation: waitAnimation 2s;
  animation-iteration-count: infinite;
}

@keyframes waitAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}