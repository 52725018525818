$header-bg: #ced4f2;
$header-bg-secondary: #ced4f280;
$accordion-header-bg: #d4daf9;

.notary-information-container {

  // Status Badge
  .notary-status-badge {
    border-radius: 26px;
    padding: 5px 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: $white;

    &.bg-warning {
      background-color: $warning;
    }

    &.bg-orange {
      background-color: $orange;
    }

    &.bg-primary {
      background-color: $primary;
    }

    &.bg-info {
      background-color: $info;
    }

    &.bg-success {
      background-color: $success;
    }
  }

  // Page header component
  #order_card {
    box-shadow: none;
  }

  .mav-header-container {
    background: $header-bg;
    border-radius: 8px;
    padding: 8px 20px;
    margin-bottom: 20px;

    &.signer {
      background: $header-bg-secondary;
    }

    .header-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-title {
      color: $primary;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      margin: 0 0 8px 0;
    }

    .header-subtitle {
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 4px;
      color: $primary;
    }

    .header-section {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .header-section-icon {
        margin-right: 10px;
        font-size: 20px;
      }

      .header-section-label {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: $gray-700;
        margin: 0;
      }
    }

    .phone-number,
    .email {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      color: $gray-700;
    }
  }

  // Accordion Flow component
  .order-flow-accordion {
    .accordion-item {
      border-top: 1px solid rgba(0, 0, 0, 0.125);
      margin-bottom: 15px;

      .zebra-striped-table {
        tr,
        td {
          border: 0;
        }
      }
    }

    .accordion-header {
      background-color: $accordion-header-bg;
      display: flex;
      align-items: center;

      img {
        margin-right: 15px;
      }
    }

    h6.accordion-title {
      margin-bottom: 0;
    }

    .accordion-item-success {

      &.accordion-header {
        background-color: $success;
      }

      .accordion-button:not(.collapsed) {
        background-color: $success;
      }

      h6.accordion-title {
        color: white;
      }

      .accordion-button.collapsed::after,
      .accordion-button:not(.collapsed)::after {
        background: url(../../images/order/checked-icon.svg);
      }
    }
  }
}