$nav-link-color: rgba(73, 80, 87, 1);
$nav-link-bg: rgba(85, 110, 230, 0.1);
$nav-link-active-bg: rgba(85, 110, 230, 1);

.vertical-tabs-page {
  background: $white;
  box-shadow: 0 12px 24px rgba(18, 38, 63, 0.0313726);
  border-radius: 4px;
  padding: 20px;

  .page-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    text-transform: capitalize;
    color: $gray-700;
    margin: 0 0 20px;
    padding: 0;
  }

  > div {
    padding: 0;
  }

  form {
    .mb-2:empty {
      margin: 0!important;
    }
  }
  // Navigation Tabs
  .nav-container {
    @media (min-width: 768px) {
      display: flex;
      width: 100%;
    }

    .nav {
      margin-bottom: 20px;

      @media (min-width: 768px) {
        min-width: 316px;
        margin-right: 25px;
      }

      .nav-link {
        font-family: Poppins;
        padding: 15px 25px;
        font-size: 13px;
        line-height: 20px;
        font-weight: 500;
        color: $nav-link-color;
        background-color:  $nav-link-bg;


        &.active {
          font-weight: 700;
          color: $white;
          background-color: $nav-link-active-bg;
        }
      }
    }

    .tab-content {
      width: 100%;
    }
  }
}