$select-menu-shadow: hsla(218, 50%, 10%, 0.1);

.select-menu {
  background-color: white;
  border-radius: 4px 4px 0 0;
  box-shadow: 0 0 0 1px $select-menu-shadow, 0 4px 11px $select-menu-shadow;
  margin-top: 8px;
  position: absolute;
  z-index: 2;
}

.select-blanket {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  z-index: 1;
}