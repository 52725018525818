$additional-costs-bg: rgba(248, 249, 250, 1);
$title-color: rgba(73, 80, 87, 1);
$accordion-border-color: rgba(206, 212, 218, 1);
$row-border-color: rgba(255, 255, 255, 1);


.additional-costs-accordion {
  background: $additional-costs-bg;
  padding: 24px;
  border-radius: 4px;

  .accordion-item {
    border: 0;

    .accordion-button {
      border-radius: 0;
      background: none;
      padding: 0;
      box-shadow: none;

      .accordion-title {
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        margin: 0;
        color: $title-color;
      }
    }

    .accordion-body {
      padding: 0;
      margin-top: 24px;
      border-top: 1px solid $accordion-border-color;

      .additional-costs-row {
        border-bottom: 1px solid $row-border-color;
        padding: 16px 0;

        label {
          margin: 0;
        }
      }
    }
  }
}