.wizard .steps .nav-item.is-invalid {
  position: relative;

  .nav-link {
    padding-right: 38px;
  }

  &:after {
    content: "\F05D6";
    display: inline-block;
    position: absolute;
    top: 12px;
    right: 10px;
    color: $red;
    font: normal normal normal 24px/1 "Material Design Icons";
    font-size: 20px;
    text-rendering: auto;
    line-height: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}