.breadcrumb {
  font-weight: 600;
  font-size: 16px !important;
  line-height: 1.2;
}

.breadcrumb-item > span {
  color: $gray-700;
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: inherit;
}