.btn-soft-success {
  background-color: #34c38f1a;
  border-color: #0000;
  color: #34c38f;
  transition: all .5s ease;
}

.btn-soft-warning {
  background-color: #f1b44c1a;
  border-color: #0000;
  color: #f1b44c;
  transition: all .5s ease;
}

.btn-soft-danger {
  background-color: #f46a6a1a;
  border-color: #0000;
  color: #f46a6a;
  transition: all .5s ease;
}