.report-header {
  background-color: $gray-300;
  height: 63px;
  border-radius: 4px;
  border: 1px solid $gray-200;
  vertical-align: middle !important;
}

.report-cell {
  font-weight: 500;
  font-size: 13px;
  vertical-align: middle;
}

.name-cell {
  width: 200px;
}

.report-table {
  font-size: 13px;
  font-family: Poppins;
  table-layout: fixed;
  width: 100%;

 tbody {
   tr:nth-child(even) {
     background-color: $gray-100;
   }
 }

  td,
  th {
    border-right: 1px solid $gray-400;
    width: 33.33%;
  }

  td:last-of-type,
  th:last-of-type {
    border-right: 0;
  }

  td:not(:first-child),
  th:not(:first-child) {
    text-align: center;
  }
}

.report-table-paginated {
  font-size: 13px;
  font-family: Poppins;
  table-layout: fixed;
  width: 100%;

  table {
      border: 0;
  }

  tbody {
  tr:nth-child(even) {
      background-color: $gray-100;
    }
  }

  th {
    background-color: $gray-300;
    height: 63px;
    vertical-align: middle !important;
  }

  thead {
      background-color: $gray-300;
      height: 63px;
      border-radius: 4px;
      border: 1px solid $gray-200;
      vertical-align: middle !important;
  }

  td,
  th {
    border-right: 1px solid $gray-400;
  }

  td:last-of-type,
  th:last-of-type {
    border-right: 0;
  }

  td:first-child,
  th:first-child {
    border-left: 0;
  }

  td:not(:first-child),
  th:not(:first-child) {
  text-align: center;
  }
}

.dealer-sales-report-table {

  td {
    vertical-align: middle;
  }

  .usage-report-header-container {
    padding: 0 !important;
    height: 75px !important;
    width: 120px;

    .usage-report-header-parent {
      display: flex;
      flex-direction: column;
      height: 100%;

      .usage-report-header-main {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
      }

      .usage-report-header {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #ced4da;
        font-size: 11px;

        .header-qty {
          border-right: 1px solid #ced4da;
        }

        .header-qty,
        .header-amt {
          width: 50%;
        }
      }
    }
  }

  .usage-report-cell-container {
    vertical-align: middle !important;
    padding: 0 !important;
    height: 60px;

    .usage-report-cell-parent {
      height: 100%;
      display: flex;

      .cell-qty,
      .cell-amt {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 100%;
      }

      .cell-qty {
        border-right: 1px solid #ced4da;
      }
    }
  }
}