.order-seal-body {
  padding: 1.25rem 1.7rem;
}

.seal-table {
  &.docs-table {
    th {
      font-weight: 500;
    }
  }

  td,
  th {
    padding-left: 0;
  }

  .label-col {
    width: 250px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;

    &.tracking-shipping {
      width: 300px;
    }
  }

  .tb-value {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }
}

.tracking-shipping-table {
  box-shadow: 0 10px 20px 0 #12263F08;
  padding: 10px 20px;
  margin: 0 -1.7rem 20px;
}

.vid-info-images {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  margin-bottom: 10px;

  .vid-image-label {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14.1232px;
    margin-bottom: 10px;
  }

  .vid-image-wrapper {
    height: 500px;

    img {
      height: 100%;
    }

    &.blured {
      filter: blur(8px);
    }
  }
}

.verif-alerts {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 15px;

  .vid-date {
    font-weight: 400;
  }

  .vid-result {
    &.passed {
      color: #00b214;
    }

    &.failed {
      color: #e02020;
    }
  }
}

.vid-verifications-table {
  font-family: "Lato";
  font-style: normal;
  font-size: 14px;
  color: #495057;

  td,
  th {
    padding-left: 0;
  }

  th {
    font-weight: 700;
    font-size: 12px;
    color: #999999;
  }

  .score-col {
    padding-right: 30px;
    text-align: right;
  }

  .scoreLow {
    color: #e02020;
    font-weight: 600;
  }

  .scoreMedium {
    color: #ff7a00;
    font-weight: 600;
  }

  .scoreHigh {
    color: #00b214;
    font-weight: 600;
  }
}

.docs-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}

.doc-page {
  border: 5px solid #eff2f7;
  width: 80%;
  margin-left: 50%;
  transform: translate(-50%, 0%);
  margin-top: 20px;
}

.doc-item-badge {
  font-size: 10px;
  font-weight: 400;
  font-family: "Lato";
  background-color: $primary;
  margin-right: 12px;
  margin-top: 4px;
  padding: 4px 8px;
}

.doc-wrapper {
  background: $gray-400;
  margin-left: calc(-1.7rem - 24px);
  margin-right: calc(-1.7rem - 24px);
  border-bottom: 25px solid $white;

  .head-row,
  .body-row {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
  }

  .head-row {
    color: #2a3042ff;
  }

  .body-row {
    color: $white;
  }

  .doc-wrapper-head {
    padding: 30px calc(1.7rem + 24px);
  }

  .doc-wrapper-body {
    background: rgba(90, 109, 222, 1);
    border-radius: 4px;
    margin: 0 1.7rem;
    padding: 18px 12px;
  }

  .doc-image {
    width: 92%;
    margin: 20px calc(1.7rem + 24px) 100px;
  }
}