$table-head-bg: #F8F9FA;
$link-color: #2B69B8;

.statements {

  /* statements table */
  .statements-table {
    .thead-light {
      background: $table-head-bg;
      color: $black;
      font-family: Poppins;
      line-height: 20px;
    }

    tr {
      text-align: center;
    }

    a {
      color: $link-color;
    }

    .invoice-number {
      color: $black;
    }
  }
}

/* Details page */
.page-title {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
}

.qb-id {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $gray-700;
}

.total-price-wrapper {
  max-width: 300px;
  margin-left: auto;
}

.statement-not-closed-text {
  font-size: 12px;
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: end;
}