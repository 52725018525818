.show-counter {
  display: flex;
  align-items: center;
  min-width: 60px;

  .countdown {
    line-height: 1.25rem;
    padding: 0 5px;
    align-items: center;
    display: flex;
    flex-direction: column;

    >p {
      margin: 0;
    }
  }

  .bidding-countdown {
    line-height: 1.25rem;
    padding: 0 2px;
    align-items: center;
    display: flex;
    flex-direction: column;

    >p {
      margin: 0;
    }
  }

  p {
    margin: 0;
  }
}

.expired-notice {
  i {
    cursor: pointer;
  }
}