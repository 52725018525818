.priority-circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.divider-faded {
  height: 1px;
  background-color: $gray-200;
  box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726);
  margin: 20px 0;
}

.word-wrap-normal {
  word-wrap: normal;
}

.striped-row {
  padding: 10px;
}

.striped-row:nth-child(even) {
  background-color: $gray-200;
}

.support-case-actions {
  .dropdown {
    .nav-btn {
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: $light;
      border-radius: 50%;
    }

    .dropdown-menu {
      box-shadow: $box-shadow;
      border: 1px solid $border-color
    }
  }
}

.support-case-quick-actions {

  .action-dropdown .btn {
    font-size: inherit;
    border-radius: 30px !important;
  }

  .action-btn,
  .select-btn {
    font-size: inherit;
    border-radius: 30px !important;
  }

  .select-btn {
    background-color: transparent;
    border-color: transparent;
    font-weight: 700;

    &:focus {
      box-shadow: 0 0 0 0.15rem rgba(85, 110, 230, .25);
    }
  }
}

.close-tag-edit-btn {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: -2px;

  i {
    font-size: 18px;
  }
}

.support-case-dropzone {

  .dropzone {
    min-height: 0;

    .dz-message {
      padding: 6px;
      font-size: inherit;
      cursor: pointer;
    }

    .dz-message-icon {
      font-size: 20px;
    }

    &.is-drag-active {

      .dz-message {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }
  }

  .support-case-file {
    a {
      color: inherit;
    }

    .mdi {
      font-size: 18px;
      vertical-align: middle;
    }

    &:not(:hover) {
      .delete-btn {
        visibility: hidden;
      }
    }
  }
}

.support-case-service {
  display: flex;
  align-items: center;

  .support-case-service-icon {
    height: 24px;
    width: 25px;
    display: flex;
    margin-right: 10px;
  }
}

.support-case-service:not(:first-child):not(:last-child) {
  margin: 6px 0;
}

.striped-row {
  .support-case-service-icon {
    height: 20px;
    width: 21px;
  }
}