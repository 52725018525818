.impersonation-alert {
  font-size: 30px;
  display: inline-flex;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $danger;
    animation-name: pulse;
    animation-duration: 2.0s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }

  &:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid $red-500;
    animation-name: pulse2;
    animation-duration: 2.0s;
    animation-delay: .4s;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1.0);
    opacity: 1;
  }

  75% {
    transform: scale(3.5);
    opacity: 0;
  }

  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

@keyframes pulse2 {
  0% {
    transform: scale(1.0);
    opacity: 1;
  }

  100% {
    transform: scale(4.0);
    opacity: 0;
  }
}