.additional-details-cell {
  width: 22%;
  max-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expanded-dt-row {
  vertical-align: top;

  td {
    padding-top: 27px;
    padding-bottom: 12px;
  }

  .timer-cell {
    padding-top: 20px;
  }

  .actions-cell {
    padding-top: 17px;
  }

  .expand-cell {
    padding-top: 0.75rem;
  }

  .additional-details-cell {
    white-space: normal;
  }

  &+tr .reset-expansion-style {
    display: revert;
    border: 0;
    padding-bottom: 10px !important;
  }

  &.notary-dt {
    td:not(.expand-cell) {
      padding-top: 26px;
    }

    &+tr {
      display: none;
    }
  }
}

.reset-expansion-style {
  padding: 0 !important;
  display: none;
}