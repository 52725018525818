.rnc__notification-item--success {
  background-color: #34c38f;
  border-left: 8px solid #2a9c72;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #34c38f;
  }
}

.rnc__notification-item--info {
  background-color: #50a5f1;
  border-left: 8px solid #4084c1;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #50a5f1;
  }
}

.rnc__notification-item--warning {
  background-color: #f1b44c;
  border-left: 8px solid #c1903d;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #f1b44c;
  }
}

.rnc__notification-item--danger {
  background-color: #f46a6a;
  border-left: 8px solid #c35555;

  .rnc__notification-close-mark,
  .rnc__notification-timer {
    background-color: #f46a6a;
  }
}