.card-preloader {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  align-items: center;
}

.card-total-cost {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-radius: 4px;
  font-family: Poppins;
  margin: 10px 0;
  border-bottom: 1px solid $table-border-color;
  background: rgba(248, 248, 251, 1);

  > label {
    margin: 0;
  }

  .total-cost-label {
    font-weight: normal;
  }

  .total-cost-value {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
  }
}

.card-notes {
  border-bottom: 1px solid $table-border-color;
  border-top: 1px solid $table-border-color;
  padding: 10px 0;

  .title {
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    margin: 10px 0;
  }

  .alert-msg {
    margin: 8px 0;
    padding: 16px;
    background: rgba(241, 180, 76, 0.1);
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
  }
}

.accounting-card {
  .nav-tabs-custom {
    background: rgba(238, 244, 253, 1);
    border: 0;

    .nav-link {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      border-radius: 0;

      &.active {
        background-color: $blue;
        color: $white;
        font-weight: 600;
      }

      &:after {
        display: none;
      }
    }
  }
}

.multiple-times-label {
  align-items: center;
  justify-content: space-between;
  background: #EFEFEF;
  padding: 8px;
  border-radius: 4px 0px 0px 0px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #62758B;
  margin-right: 10px;

  .additional-costs-row & {
    margin-left: 10px;
  }

  .vertical-tabs-page & {
    min-width: 115px;
  }
}

.card {
  .instruction-description {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    color: $gray-600;
  }
}
