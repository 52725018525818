//
// Help.scss
//

.help-description {
  width: 45%;
  text-align: center;
  font-size: 13px;
}

.article-list-container {
  width: 100%;
}

.article-list {
  border-top: 1px solid #EAEAEA;
  padding-top: 21px;
}

.help-article-item {
  width: 100%;
  background-color: #EFEFEF;
  margin-top: 21px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 21px;
  cursor: pointer;
}

.article-title-button {
  padding: 10px;
  text-align: start;
  color: #556EE6;
}

.article-iframe {
  width: 100%;
  height: calc(100vh - 400px);
  margin-top: 12px;
}

.article-item-container {
  width: 100%;
}

.article-container {
  display: flex;
  gap: 24px;
  padding: 10px 34px;
}

.video-container {
  width: 75%;
}

@include media-breakpoint-down(md) {
  .article-list-container {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .article-container {
    flex-direction: column;
    padding: 0px;
  }

  .video-container {
    width: 100%;
  }
}