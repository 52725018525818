//
// Chat.scss
//

.chat-leftsidebar {
  @media (min-width: 992px) {
    min-width: 260px;
  }

  @media (min-width: 1200px) {
    min-width: 380px;
  }

  .chat-leftsidebar-nav {
    .nav {
      background-color: $card-bg;
    }

    .tab-content {
      min-height: 488px;
    }
  }
}

.chat-noti-dropdown {
  &.active {
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      background-color: $danger;
      border-radius: 50%;
      right: 0;
    }
  }

  .btn {
    padding: 6px;
    box-shadow: none;
    font-size: 20px;
  }
}

.chat-search-box {
  .form-control {
    border: 0;
  }
}

.chat-list {
  margin: 0;

  li {
    &.active {
      a {
        background-color: $card-bg;
        border-color: transparent;
        box-shadow: $box-shadow;
      }
    }

    a {
      display: block;
      padding: 14px 16px;
      color: $gray-600;
      transition: all 0.4s;
      border-top: 1px solid $border-color;
      border-radius: 4px;

      &:hover {
        background-color: $card-bg;
        border-color: transparent;
        box-shadow: $box-shadow;

      }
    }
  }
}


.user-chat-nav {
  .dropdown {
    .nav-btn {
      height: 40px;
      width: 40px;
      line-height: 40px;
      box-shadow: none;
      padding: 0;
      font-size: 16px;
      background-color: $light;
      border-radius: 50%;
    }

    .dropdown-menu {
      box-shadow: $box-shadow;
      border: 1px solid $border-color
    }
  }
}


.chat-conversation {
  li {
    clear: both;
  }

  .chat-day-title {
    position: relative;
    text-align: center;
    margin-bottom: 24px;

    .title {
      background-color: $card-bg;
      position: relative;
      z-index: 1;
      padding: 6px 24px;
    }

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $border-color;
      top: 10px;
    }

    .badge {
      font-size: 12px;
    }
  }

  .conversation-list {
    margin-bottom: 24px;
    display: flex;
    position: relative;

    .ctext-wrap {
      padding: 12px 24px;
      background-color: rgba($primary, 0.1);
      border-radius: 8px 8px 8px 0px;
      overflow: hidden;

      .conversation-name {
        font-weight: $font-weight-semibold;
        color: $primary;
        margin-bottom: 4px;
      }
    }

    .dropdown {
      float: right;

      .dropdown-toggle {
        font-size: 18px;
        padding: 4px;
        color: $gray-600;

        @media (max-width: 575.98px) {
          display: none;
        }
      }

      .dropdown-menu {
        box-shadow: $box-shadow;
        border: 1px solid $border-color
      }
    }

    .chat-time {
      font-size: 12px;
    }
  }

  .right {
    .conversation-list {
      justify-content: flex-end;

      .ctext-wrap {
        background-color: $light;
        text-align: right;
        border-radius: 8px 8px 0px 8px;
        max-width: 80%;
      }

      .dropdown {
        .dropdown-menu {
          top: auto !important;
        }
      }

      &.last-chat {
        .conversation-list {
          &:before {
            right: 0;
            left: auto;
          }
        }
      }
    }

  }

  .last-chat {
    .conversation-list {
      &:before {
        content: "\F0009";
        font-family: "Material Design Icons";
        position: absolute;
        color: $primary;
        right: 0;
        bottom: 0;
        font-size: 16px;

        @media (max-width: 575.98px) {
          display: none;
        }
      }
    }
  }
}

.chat-input-section {
  border-top: 1px solid $border-color;
}

.chat-input-section-mobile {
  border-top: 1px solid $border-color;
  width: 100%;
  position: fixed;
  background-color: white;
  bottom: 0;
  left: 0;
  z-index: 1050;
}

.messages-list-mobile {
  padding: 30px 20px 50px 20px
}

.chat-input {
  border-radius: 30px;
  min-height: 40px;
  background-color: $light !important;
  border-color: $light !important;
  padding: 10px 45px 10px 20px;
  resize: none;
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  user-select: text;
  -moz-user-select: text;
}

.chat-input-links {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);

  li {
    a {
      font-size: 16px;
      line-height: 36px;
      padding: 0px 4px;
      display: inline-block;
    }
  }
}

.chat-send {
  @media (max-width: 575.98px) {
    min-width: auto;
  }
}

.message-dots-icon {
  margin-right: 0px;
  font-size: 18px;
  height: 18px;
  width: 18px;
}

.message-dots-icon:hover {
  opacity: 0.7;
}

.dropdown-delete-message {
  margin-right: 25px;
  margin-top: -10px
}

.mute-icon {
  border: 2px solid #EEF0FD;
  width: 57px;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
  color: #556EE6;
  font-size: 24px;
}

.mute-btn {
  background: #EEF0FD;
  width: 35px;
  height: 35px;
  border: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #556ee6;
  font-size: 16px;
  padding: 0;

  &:hover {
    .mute-message {
      opacity: 1;
      visibility: visible;
    }
  }
}

.mute-message {
  box-shadow: -5px 6px 10.9px 0px #00000040;
  background: #fff;
  padding: 8px;
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  color: #495057;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  bottom: -40px;
  width: 150px;
  z-index: 90;
}

.muted-chanel {
  position: absolute;
  bottom: 0;
  left: 25px;
}

.carousel-item-template {
  border: 1px solid #556EE6;
  border-radius: 8px;
  color: #556EE6;
  padding: 8px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  cursor: pointer;
}

.template-action-button {
  background-color: #EFEFEF;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.template-action-icon {
  font-size: 12px;
}

.message-content {
  white-space: pre-line;
}

// Placeholder for contenteditable
.chat-input.chat-input-blank:before {
  content: attr(placeholder);
  pointer-events: none;
  color: #74788d;
  /* For Firefox */
  display: block;
  position: absolute;
}

.list-group-item {
  height: 65px;
}

.chat-p {
  min-height: 20px;
  margin: 0;
  line-height: 1.5;
}