.col-notif-1 {
  display: flex;
  align-items: center;

  .checkbox-wrapper-mail {
    margin-left: 20px;
    vertical-align: middle;
  }

  div,
  .title {
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.col-notif-2 {
  display: flex;
  justify-content: flex-end;

  .date {
    display: inline;
    position: absolute;
    right: 40px;
  }
}

.notification-item {
  .d-flex {
    padding: 0.75rem 1rem;

    &:hover {
      background-color: $gray-300;
    }
  }

  .unread {
    background-color: $gray-300;
    font-weight: 500;
    color: darken($dark, 5%);

    a {
      color: darken($dark, 5%);
      font-weight: 500;
    }
  }
}

.message-list {
  margin-bottom: 0;
}

.notification-list {
  margin-bottom: 150px;
}