$title-color: #556EE6FF;
$header-description-color: #74788DFF;
$status-label-color: #495057FF;

/* Instructions */
.instructions {
  padding: 24px 24px 34px 24px;
  box-shadow: 0 10px 20px 0 rgba(18, 38, 63, 0.0314);
  font-size: 13px;
  line-height: 20px;

  &-title {
    color: $title-color;
    font-size: 13px;
    line-height: 19px;
  }
}

/* Quickbooks Header */
.header-description {
  color: $header-description-color;
}

.qb-user-name,
.status-label {
  color: $status-label-color;
}
