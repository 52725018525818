$helperSectionWidth: 400px;

body.help-sidebar-enable {
  #help_sidebar {
    display: block;
  }

  .main-content,
  .footer,
  #page-topbar {
    margin-right: $helperSectionWidth;
  }
}

#help_sidebar {
  display: none;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 2000;
  background: white;
  width: $helperSectionWidth;
  padding: 20px;
  box-shadow: -18px 10px 20px 0px #12263F08;

  .help-title {
    font-size: 16px;
    font-weight: 600;
    color: #74788D;
  }

  .help-search-input {
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }

  .help-search-btn {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    pointer-events: none;
  }

  #help_sidebar_list {
    margin-top: 25px;

    .article-item {
      margin: 20px 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .article-title {
        padding: 0 10px 0 0;
        text-align: start;
      }

      .article-header {
        font-size: 16px;
        font-weight: 600;
        text-align: start;
      }
    }

    .help-spinner {
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}