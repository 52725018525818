.payment-plan-description {

  p {
    margin: 0 0 5px 25px;
    position: relative;
  }

  i {
    position: absolute;
    top: -1px;
    right: 100%;
    font-size: 20px;
    margin-right: 5px;
  }
}